<template>
    <div id="users-list">
        <v-card>
            <v-card-title> {{ getTrad('List.searchFilter') }} </v-card-title>

            <v-card-text class="d-flex align-center flex-wrap pb-0">
                <v-text-field v-model="searchText" @change="getRoles" :placeholder="getTrad('List.search')" outlined hide-details dense class="user-search me-3 mb-4"></v-text-field>
                <v-spacer></v-spacer>
                <div class="d-flex align-center flex-wrap">
                    <v-btn color="primary" class="mb-4 me-3" @click.stop="toAddItem">
                        <v-icon>{{ mdiPlus }}</v-icon>
                        <span>{{ getTrad('Roles.List.addRole') }}</span>
                    </v-btn>
                </div>
            </v-card-text>

            <v-data-table
                :page.sync="query.pagination.page"
                :items-per-page.sync="query.pagination.pageSize"
                :server-items-length="serverRoles"
                checkbox-color="primary"
                v-model="selectedRows"
                :headers="tableColumns"
                :items="roles"
                :loading="isLoading"
                :footer-props="footerProps"
                show-select>
                <template #[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="toEditItem(item)"> {{ mdiPencil }} </v-icon>
                    <v-icon small @click="toDeleteItem(item)"> {{ mdiDeleteOutline }} </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <Form v-model="isFormActive" :action="formAction" :role="itemToEdit" :_pluginPermissions="pluginPermissions" @action="getRoles" />
    </div>
</template>

<script>
    import { mdiPlus, mdiDotsVertical, mdiDeleteOutline, mdiPencil, mdiEye } from '@mdi/js';
    import getTrads from '../../i18n/getTrad';
    import _ from 'lodash';
    import { getRoles, getPermissions } from '../../api/roles';
    import { routeName } from '../../constants';
    import Form from './Form.vue';

    export default {
        name: 'Roles-List',
        mixins: [getTrads],
        components: {
            Form,
        },
        data() {
            return {
                isFormActive: false,
                formAction: 'add',
                isLoading: false,
                routeName,
                mdiPlus,
                mdiDotsVertical,
                mdiDeleteOutline,
                mdiPencil,
                mdiEye,

                footerProps: {
                    'items-per-page-options': [10, 20, 50],
                },

                users: [],
                pluginPermissions: [],
                roles: [],
                query: {
                    filters: {},
                    populate: ['permissions'],
                    pagination: {
                        page: 1,
                        pageSize: 10,
                    },
                },
                serverRoles: 0,
                searchText: '',
                itemToEdit: {},

                selectedRows: [],
                tableColumns: [
                    { text: 'role.name', value: 'name' },
                    { text: 'role.description', value: 'description' },
                    { text: 'role.users', value: 'users.length' },
                    {
                        text: 'List.actions',
                        value: 'actions',
                        align: 'center',
                        sortable: false,
                    },
                ],
            };
        },
        watch: {
            'query.pagination': {
                handler() {
                    this.getRoles();
                },
                deep: true,
            },
        },
        created() {
            this.getRoles();
            this.getPermissions();
            this.tableColumns.forEach((column) => {
                column.text = this.getTrad(column.text);
            });
        },
        methods: {
            async getPermissions() {
                await getPermissions({
                    populate: ['permissions'],
                }).then((response) => {
                    this.pluginPermissions = response.data.data;
                });
            },
            toAddItem() {
                this.isFormActive = !this.isFormActive;
                this.formAction = 'add';
            },
            toEditItem(item) {
                this.formAction = 'edit';
                this.itemToEdit = item;
                this.isFormActive = !this.isFormActive;
            },
            toDeleteItem(item) {},
            async getRoles() {
                this.isLoading = true;
                await getRoles({ populate: ['users', 'pluginPermissions'] }).then((response) => {
                    this.roles = response.data.data;
                    this.serverRoles = response.data.meta.pagination.total;
                });
                this.isLoading = false;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
